import { QueueSection } from "components/organisms/queue";
import { useSetting } from "hooks";

export const QueueTemplates = () => {
  const { settingForm } = useSetting();
  return !settingForm.isDivideDisplay ? (
    <div className="p-3 grid gap-3 bg-white">
      <QueueSection
        divided={settingForm.isDivideDisplay}
        type="success"
        title={settingForm.readyLabel}
      />
      <QueueSection
        divided={settingForm.isDivideDisplay}
        type="muted"
        title={settingForm.pendingLabel}
      />
    </div>
  ) : (
    <div className="flex gap-1 bg-gray-800">
      <div className="p-3 grid gap-3 bg-white w-full">
        <QueueSection
          divided={settingForm.isDivideDisplay}
          type="success"
          title={settingForm.readyLabel}
        />
        <QueueSection
          divided={settingForm.isDivideDisplay}
          type="muted"
          title={settingForm.pendingLabel}
        />
      </div>
      <div className="p-3 grid gap-3 bg-white w-full">
        <QueueSection
          divided={settingForm.isDivideDisplay}
          type="success"
          title={settingForm.readyLabel}
        />
        <QueueSection
          divided={settingForm.isDivideDisplay}
          type="muted"
          title={settingForm.pendingLabel}
        />
      </div>
      <div className="p-3 grid gap-3 bg-white w-full">
        <QueueSection
          divided={settingForm.isDivideDisplay}
          type="success"
          title={settingForm.readyLabel}
        />
        <QueueSection
          divided={settingForm.isDivideDisplay}
          type="muted"
          title={settingForm.pendingLabel}
        />
      </div>
    </div>
  );
};
