import { SelectIcon } from "components/molecules/select-icon";
import { Button } from "components/ui/button";
import { useInstallationContext } from "internal/installation/state/context";

const options = [
  {
    value: "PT. Pinus Merah Abadi",
    label: "PT. Pinus Merah Abadi",
    icon: "https://e7.pngegg.com/pngimages/620/316/png-clipart-pt-pinus-merah-abadi-red-pine-tree-tarumanagara-university-tree-leaf-logo.png",
  },
  {
    value: "PT. Richeese Kuliner Indonesia",
    label: "PT. Richeese Kuliner Indonesia",
    icon: "https://media.licdn.com/dms/image/C560BAQF8P-TheP2eFQ/company-logo_200_200/0/1631316247449?e=2147483647&v=beta&t=QCMF1oD2MOlUuIdFzcfubOiG_bKH358ARASyV_DOJfo",
  },
  {
    value: "PT. Richeese Factory",
    label: "PT. Richeese Factory",
    icon: "https://freshmart.ph/cdn/shop/collections/richeese_1200x1200.png?v=1674194155",
  },
  {
    value: "PT. Kieber Propertindo",
    label: "PT. Kieber Propertindo",
    icon: "https://media.licdn.com/dms/image/C510BAQH0OryHqzAj3g/company-logo_200_200/0/1630565137158?e=2147483647&v=beta&t=PlOcFC7WpGvKFX7BaLkIN4wi6hbumkIFmN2fV7vXcdg",
  },
  {
    value: "PT. EDOT",
    label: "PT. EDOT",
    icon: "https://media.licdn.com/dms/image/C560BAQFWJ7hQ1kN_Zw/company-logo_200_200/0/1670131884186/edot_eshop_logo?e=1710374400&v=beta&t=ab73oVzZrbePU8uiwNPxMaE7AtkhRJKh54HaoLX419Q",
  },
];

export const InstallationForm = () => {
  const { branch, setBranch, company, setCompany, mutate } =
    useInstallationContext();
  return (
    <div className="text-center max-w-[350px] w-full px-6">
      <h2 className="text-3xl font-bold mb-6">Hello Aurora,</h2>
      <p className="mb-8">
        Please select the branch of your company to continue installation
      </p>
      <SelectIcon
        options={options}
        label="Select Company"
        value={company}
        onChange={setCompany}
        placeholder="Select company"
        className="mb-6 h-12"
      />
      <SelectIcon
        options={options}
        label="Select Branch"
        value={branch}
        onChange={setBranch}
        placeholder="Select branch"
        className="mb-6 h-12"
      />
      <Button
        variant="secondary"
        disabled={!company || !branch}
        className="block w-full bg-secondary mt-8"
        onClick={() => mutate({ branch, company })}
      >
        Install QDS
      </Button>
    </div>
  );
};
