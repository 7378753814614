import { useEffect, useReducer, useState } from "react";
import { SettingFormType, SettingStateType } from "../type";
import { defaultSettingForm } from "../const";
import { toast } from "sonner";
import { FindSetting, UpdateSetting } from "../http";
import ErrorHandler from "internal/base/middleware/error-handler";

export const useSettingState = (): SettingStateType => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [settingForm, setSettingForm] = useReducer(
    (prev: SettingFormType, next: any): SettingFormType => {
      return { ...prev, ...next };
    },
    defaultSettingForm
  );

  const [form, updateForm] = useReducer(
    (prev: SettingFormType, next: Partial<SettingFormType>) => {
      return { ...prev, ...next };
    },
    settingForm
  );

  useEffect(() => {
    FindSetting()
      .then((res) => {
        const data = res.data?.data?.[0] || {};

        setSettingForm({
          showQueue: data?.connection.status,
          ipAdress: data?.connection.ip_address,
          isDivideDisplay: data?.display.devide_by_order_type,
          queueExpiry: data?.display.queue_number_expired,
          pendingLabel: data?.status_label?.[0].name,
          readyLabel: data?.status_label?.[1].name,
          // slideImages: data?.images,
        });
      })
      .catch(ErrorHandler);
  }, []);

  const handleSave = (values: SettingFormType) => {
    setIsLoading(true);

    UpdateSetting(values)
      .then(() => {
        setSettingForm(values);
        toast.success("Success Save Configuration");
      })
      .catch(ErrorHandler)
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  };

  return {
    open,
    setOpen,
    setSettingForm,
    handleSave,
    settingForm,
    isLoading,
    form,
    updateForm,
  };
};
