import { SettingItem, SettingItemList } from "components/atoms/setting-item";
import { UploadIcon } from "components/icons";
import { DialogBase } from "components/molecules/dialog";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import { useCustomerSetting } from "hooks/useCustomerSetting";
import { FontSizeEnum } from "internal/setting/const";
import { Trash2 } from "lucide-react";
import { useRef } from "react";

export default function CustomerSettingDialog() {
  const {
    open,
    setOpen,
    handleSave,
    settingForm,
    form,
    updateForm,
    isLoading,
  } = useCustomerSetting();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    if (hiddenFileInput) {
      hiddenFileInput.current?.click();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    console.log(files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <DialogBase
      title="Customer Display Configuration"
      open={open}
      onClose={() => {
        setOpen(false);
        updateForm(settingForm);
      }}
      onSave={() => handleSave(form)}
      disabled={isLoading}
    >
      <SettingItemList label="Connection">
        <SettingItem label="Enable Customer Display">
          <div className="flex items-center gap-3">
            <Switch
              checked={form.showCustomerDisplay}
              onCheckedChange={(e) => updateForm({ showCustomerDisplay: e })}
              className="data-[state=checked]:bg-secondary"
            />
            <span>{form.showCustomerDisplay ? "on" : "off"}</span>
          </div>
        </SettingItem>
        <SettingItem label="IP Address">{form?.ipAdress}</SettingItem>
      </SettingItemList>

      <SettingItemList label="Appearance">
        <SettingItem label="Font Size" width="w-full pr-5">
          <div className="flex justify-between gap-x-4">
            {Object.entries(FontSizeEnum).map(([key, value]) => (
              <div key={key} className="flex items-center space-x-2 w-full">
                <Button
                  onClick={() =>
                    updateForm({
                      fontSize: value,
                    })
                  }
                  variant={form.fontSize !== value ? "outline" : "secondary"}
                  value="default"
                  className="flex justify-around w-full py-5"
                >
                  <span>{key}</span>
                  <span className={`text-${value}`}>ABC</span>
                </Button>
              </div>
            ))}
          </div>
        </SettingItem>
      </SettingItemList>

      <SettingItemList label="Slideshow Images" direction="row">
        <SettingItem label="Scale Slideshow to fit">
          <div className="flex items-center gap-3">
            <Switch
              checked={form.scaleSlide}
              onCheckedChange={(e) => updateForm({ scaleSlide: e })}
              className="data-[state=checked]:bg-secondary"
            />
            <span>{form.scaleSlide ? "on" : "off"}</span>
          </div>
        </SettingItem>
        <SettingItem label="Slideshow interval">
          <Input
            type="number"
            placeholder="Interval"
            value={form.slideInterval}
            onChange={(e) =>
              updateForm({ slideInterval: Number(e.target.value) })
            }
            suffix={<span className="text-sm">Seconds</span>}
          />
        </SettingItem>
      </SettingItemList>
      <SettingItemList direction="col">
        <SettingItem width="w-full" label="Upload images">
          <div
            onClick={handleClickUpload}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className="border flex items-center gap-2 p-3 cursor-pointer"
          >
            <Button className="bg-[#E9F7FC] hover:bg-[#e6f0f4] text-secondary gap-2">
              <UploadIcon />
              Upload images
            </Button>
            <span>or drop files here</span>

            <Input
              ref={hiddenFileInput}
              id="picture"
              type="file"
              className="hidden"
            />
          </div>
          <p className="mt-2 text-gray-600 text-sm">
            Max size 5mb, resolution 637x1080px, up to 3 images
          </p>
        </SettingItem>

        <div className="flex gap-3">
          {form.slideImages?.map((image, idx) => (
            <div
              key={`${image}${idx}`}
              className="border rounded-xl relative overflow-hidden"
            >
              <img src={image} alt="uploaded" />

              <Button
                className="absolute top-2 right-2"
                variant="outline"
                size="icon"
              >
                <Trash2 />
              </Button>
            </div>
          ))}
        </div>
      </SettingItemList>
    </DialogBase>
  );
}
