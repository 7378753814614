import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function scrollTo(id: string, offset: number) {
  const element = document.getElementById(id);

  if (element) {
    const selected = element?.children?.[1];
    const currentOffset =
      -element?.children?.[1].children?.[0]?.getBoundingClientRect()?.top;

    const headerOffset = currentOffset + offset;
    const elementPosition = selected.getBoundingClientRect().top;
    const offsetPosition = elementPosition + headerOffset;

    selected.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}

export function formatPrice(price: number): string {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  return formatter.format(price);
}
