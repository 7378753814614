import HomeScreen from "screens/home";
import LoginScreen from "screens/login";
import { createBrowserRouter } from "react-router-dom";
import OnboardingScreen from "screens/onboarding";
import CustomerDisplay from "screens/customer-display";
import { SettingProvider } from "hooks";
import ThankYou from "screens/thank-you";
import { CustomerSettingProvider } from "hooks/useCustomerSetting";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SettingProvider>
        <HomeScreen />
      </SettingProvider>
    ),
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/onboarding",
    element: <OnboardingScreen />,
  },
  {
    path: "/customer-display",
    element: (
      <CustomerSettingProvider>
        <CustomerDisplay />
      </CustomerSettingProvider>
    ),
  },
  {
    path: "/thank-you",
    element: <ThankYou />,
  },
]);
