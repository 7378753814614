import { HTMLAttributes } from "react";

export function UploadIcon(props: HTMLAttributes<SVGElement>) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91667 14.1667V9.16667M7.91667 9.16667L6.25 10.8333M7.91667 9.16667L9.58333 10.8333M17.5 8.33333V12.25C17.5 16 16 17.5 12.25 17.5H7.75C4 17.5 2.5 16 2.5 12.25V7.75C2.5 4 4 2.5 7.75 2.5H12.0833M17.5 8.33333H15.4167C12.9167 8.33333 12.0833 7.5 12.0833 5V2.5M17.5 8.33333L12.0833 2.5"
        stroke="#27AAE1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
