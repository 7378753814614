"use-strict";

export const GetToken = (): string => {
  const tmp =
    localStorage.getItem(process.env.REACT_APP_TOKEN_USER || "qds-token") || "";
  return tmp;
};

export const SetToken = (token: string): void => {
  localStorage.setItem(process.env.REACT_APP_TOKEN_USER || "qds-token", token);
};

export const ClearToken = (): void => {
  localStorage.removeItem(process.env.REACT_APP_TOKEN_USER || "qds-token");
};
