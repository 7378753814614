import { Button } from "components/ui/button";
import { ScrollArea } from "components/ui/scroll-area";
import { useCustomerSetting } from "hooks/useCustomerSetting";
import { FontSizeEnum } from "internal/setting/const";
import { cn, formatPrice, scrollTo } from "lib/utils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useMemo } from "react";

const data = [
  {
    id: 1,
    qty: 2,
    description: "Fire Wings 4pcs",
    extra: "Lev 1",
    price: 100000,
  },
  {
    id: 2,
    qty: 2,
    description: "Rice",
    extra: "",
    price: 10000,
  },
  {
    id: 3,
    qty: 2,
    description: "Fire French fries",
    extra: "Lev 1",
    price: 20000,
  },
  {
    id: 4,
    qty: 2,
    description: "Fire Cheese Wings",
    extra: "Lev 1",
    price: 100000,
  },
  {
    id: 5,
    qty: 4,
    description: "Rice",
    extra: "Wing",
    price: 10000,
  },
  {
    id: 6,
    qty: 1,
    description: "Cheese French fries",
    extra: "Lev 1",
    price: 20000,
  },
];

const BillItem = ({
  label,
  value,
  fontSize,
}: {
  label: string;
  value: string;
  fontSize: string;
}) => {
  return (
    <div
      className={cn(
        "flex items-center justify-between mb-2 font-bold text-xl",
        `text-${fontSize}`
      )}
    >
      <p>{label}</p>
      <p>{value}</p>
    </div>
  );
};

const SCROLL_ID = "scroll-order-id";

export default function CustomerTemplates() {
  const { settingForm } = useCustomerSetting();
  const { fontSize } = settingForm;

  const handleClick = (direction: string) => {
    const offset = direction === "down" ? 120 : -120;
    scrollTo(SCROLL_ID, offset);
  };

  const calcFontSize = useMemo(() => {
    const higer: { [key in FontSizeEnum]: string } = {
      [FontSizeEnum.Small]: FontSizeEnum.Large,
      [FontSizeEnum.Medium]: FontSizeEnum.Large,
      [FontSizeEnum.Large]: "3xl",
    };

    return `text-${higer[fontSize]}`;
  }, [fontSize]);

  return (
    <div
      className={cn(
        "px-6 pt-3 pb-6 h-[calc(100vh-64px)] flex flex-col justify-between text-primary",
        `text-${fontSize}`
      )}
    >
      <div>
        <div className="mb-3">
          <div className="flex items-center justify-between mb-3">
            <h2 className="text-xl font-semibold text-[#435970] tracking-wide">
              ORDER DETAIL
            </h2>
            <div className="flex gap-2">
              <Button
                onClick={() => handleClick("up")}
                size="icon"
                className="bg-muted hover:bg-[#e7eef4]"
              >
                <ChevronUp strokeWidth={1} color="#667A8E" />
              </Button>
              <Button
                onClick={() => handleClick("down")}
                size="icon"
                className="bg-muted hover:bg-[#e7eef4]"
              >
                <ChevronDown strokeWidth={1} color="#667A8E" />
              </Button>
            </div>
          </div>
          <ScrollArea id={SCROLL_ID} className="relative rounded-lg h-[230px]">
            <table
              className={cn(
                "w-full text-left text-xl divide-y border-b",
                `text-${fontSize}`
              )}
            >
              <thead className="bg-muted sticky top-0">
                <tr>
                  <td className="py-2 px-4 divide-y text-center w-20 border-x">
                    Qty
                  </td>
                  <td className="py-2 px-4 divide-y border-x">Description</td>
                  <td className="py-2 px-4 divide-y text-center w-40 border-x">
                    Price
                  </td>
                </tr>
              </thead>
              <tbody className="divide-y">
                {data.map((data, idx) => (
                  <tr
                    key={`${data.id}${idx}`}
                    className="font-bold text-primary"
                  >
                    <td className="py-2 px-4 divide-y text-center border-x">
                      {data.qty}
                    </td>
                    <td className="py-2 px-4 divide-y border-x">
                      <div>
                        <div>{data.description}</div>
                        {data.extra && <div>{data.extra}</div>}
                      </div>
                    </td>
                    <td className="py-2 px-4 divide-y border-x text-right">
                      {formatPrice(data.price)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ScrollArea>
        </div>

        <div className="mb-3">
          <div className="flex items-center justify-between mb-3">
            <h2 className="text-xl font-semibold text-[#435970] tracking-wide">
              BILL DETAIL
            </h2>
          </div>

          <div className="bg-[#F6F8FC] p-3 rounded-md">
            <BillItem fontSize={fontSize} label="Sub total" value="Rp130.000" />
            <BillItem fontSize={fontSize} label="PPN (10%)" value="Rp1.300" />
            <BillItem
              fontSize={fontSize}
              label="Service fee (5%)"
              value="Rp650"
            />
            <BillItem fontSize={fontSize} label="Discount" value="-" />
            <BillItem fontSize={fontSize} label="Rounding" value="-" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 mt-auto">
        <div className="flex items-center justify-between text-xl font-bold">
          <p className={`text-${fontSize}`}>Grand Total (5)</p>
          <p className={`text-${fontSize}`}>Rp131.950</p>
        </div>
        <div className="flex items-center justify-between text-xl font-bold text-lightdanger">
          <p className={`text-${fontSize}`}>Payment Total</p>
          <p className={cn(calcFontSize)}>Rp131.950</p>
        </div>
      </div>
    </div>
  );
}
