import { ChecklistIcon } from "components/icons/Checklist";
import Authn from "internal/base/middleware/authn";
import { formatPrice } from "lib/utils";

const Item = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="text-center">
      <p className="text-dimmed text-xl mb-2">{label}</p>
      <p className="text-primary font-bold text-2xl">{value}</p>
    </div>
  );
};

const ThankYou = () => {
  return (
    <div className="h-dvh w-screen select-none">
      <div className="h-[400px] md:h-[438px] bg-[#E9F7FC] w-full text-center flex flex-col justify-center items-center">
        <div className="flex flex-col gap-4 mb-7 items-center">
          <ChecklistIcon className="w-20 h-20" />
          <p className="text-primary font-semibold text-xl">Payment received</p>
        </div>
        <div className="flex flex-col gap-4">
          <h1 className="text-7xl sm:text-8xl font-bold text-secondary">
            Thank you
          </h1>
          <h3 className="text-2xl font-bold text-secondary">
            for your purchase
          </h3>
        </div>
      </div>
      <div className="h-max w-full flex justify-center p-6 md:p-12">
        <div className="flex flex-col sm:flex-row gap-4 md:gap-14">
          <Item label="Payment total" value={formatPrice(131950)} />
          <Item label="Payment method" value="Card - Mandiri" />
          <Item label="Change" value="0" />
        </div>
      </div>
    </div>
  );
};

export default Authn(ThankYou);
