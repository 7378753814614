import { SettingDialog } from "components/organisms/dialog";
import { QueueHeader } from "components/organisms/header";
import { QueueTemplates } from "components/templates/QueueTemplates";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "components/ui/carousel";
import { useSetting } from "hooks";
import Authn from "internal/base/middleware/authn";
import { cn } from "lib/utils";

const HomeScreen = () => {
  const { settingForm } = useSetting();

  return (
    <>
      <div className="grid grid-cols-3 min-h-screen ">
        <div
          className={cn("col-span-1 hidden lg:block min-h-screen h-max", {
            "lg:hidden": settingForm.isDivideDisplay,
          })}
        >
          <Carousel
            autoPlayTimeout={settingForm.queueExpiry || 6000}
            opts={{ loop: true }}
          >
            <CarouselContent>
              {settingForm.slideImages.map((value, idx) => (
                <CarouselItem key={`${value}${idx}`} className="h-full min-h-screen">
                  <img
                    src={value}
                    alt="carousel"
                    className="h-full min-h-screen object-cover"
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </div>
        <div
          className={cn(
            "lg:col-span-2 col-span-3 white h-screen overflow-hidden",
            {
              "lg:col-span-3": settingForm.isDivideDisplay,
            }
          )}
        >
          <QueueHeader />
          <QueueTemplates />
        </div>
      </div>

      <SettingDialog />
    </>
  );
};

export default Authn(HomeScreen);
