import { SettingItem, SettingItemList } from "components/atoms/setting-item";
import { UploadIcon } from "components/icons";
import { DialogBase } from "components/molecules/dialog";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import { useSetting } from "hooks";
import { Trash2 } from "lucide-react";
import { useRef } from "react";

export default function SettingDialog() {
  const {
    open,
    setOpen,
    handleSave,
    settingForm,
    isLoading,
    form,
    updateForm,
  } = useSetting();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    if (hiddenFileInput) {
      hiddenFileInput.current?.click();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    console.log(files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <DialogBase
      title="QDS Configuration"
      open={open}
      onClose={() => {
        setOpen(false);
        updateForm(settingForm);
      }}
      onSave={() => handleSave(form)}
      disabled={isLoading}
    >
      <SettingItemList label="Connection">
        <SettingItem label="Enable Queue Display">
          <div className="flex items-center gap-3">
            <Switch
              checked={form.showQueue}
              onCheckedChange={(e) => updateForm({ showQueue: e })}
              className="data-[state=checked]:bg-secondary"
            />
            <span>{form.showQueue ? "on" : "off"}</span>
          </div>
        </SettingItem>
        <SettingItem label="IP Address">{form.ipAdress}</SettingItem>
      </SettingItemList>

      <SettingItemList label="Display">
        <SettingItem label="Divide by Order type">
          <div className="flex items-center gap-3">
            <Switch
              checked={form.isDivideDisplay}
              onCheckedChange={(e) => updateForm({ isDivideDisplay: e })}
              className="data-[state=checked]:bg-secondary"
            />
            <span>{form.isDivideDisplay ? "on" : "off"}</span>
          </div>
        </SettingItem>
        <SettingItem label="Queue number expiry">
          <Input
            type="number"
            placeholder="Preparing"
            value={form.queueExpiry}
            onChange={(e) =>
              updateForm({ queueExpiry: Number(e.target.value) })
            }
            suffix={<span className="text-sm">Seconds</span>}
          />
        </SettingItem>
      </SettingItemList>

      <SettingItemList label="Status label">
        <SettingItem label="Status 1">
          <Input
            value={form.pendingLabel}
            onChange={(e) => updateForm({ pendingLabel: e.target.value })}
          />
        </SettingItem>
        <SettingItem label="Status 2">
          <Input
            value={form.readyLabel}
            onChange={(e) => updateForm({ readyLabel: e.target.value })}
          />
        </SettingItem>
      </SettingItemList>

      <SettingItemList label="Slideshow Images" direction="col">
        <SettingItem width="w-full" label="Upload images">
          <div
            onClick={handleClickUpload}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className="border flex items-center gap-2 p-3 cursor-pointer"
          >
            <Button className="bg-[#E9F7FC] hover:bg-[#e6f0f4] text-secondary gap-2">
              <UploadIcon />
              Upload images
            </Button>
            <span>or drop files here</span>

            <Input
              ref={hiddenFileInput}
              id="picture"
              type="file"
              className="hidden"
            />
          </div>
          <p className="mt-2 text-gray-600 text-sm">
            Max size 5mb, resolution 637x1080px, up to 3 images
          </p>
        </SettingItem>

        <div className="flex gap-3">
          {form.slideImages?.map((image, idx) => (
            <div
              key={`${image}${idx}`}
              className="border rounded-xl relative overflow-hidden"
            >
              <img src={image} alt="uploaded" />

              <Button
                className="absolute top-2 right-2"
                variant="outline"
                size="icon"
              >
                <Trash2 />
              </Button>
            </div>
          ))}
        </div>
      </SettingItemList>
    </DialogBase>
  );
}
