import CustomerSettingDialog from "components/organisms/dialog/CustomerSettingDialog";
import { CustomerHeader } from "components/organisms/header";
import CustomerTemplates from "components/templates/CustomerTemplates";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "components/ui/carousel";
import { useCustomerSetting } from "hooks/useCustomerSetting";
import Authn from "internal/base/middleware/authn";
import { cn } from "lib/utils";
import { SettingsIcon } from "lucide-react";
import { useState } from "react";

const CustomerDisplay = () => {
  const { setOpen, settingForm, updateForm } = useCustomerSetting();
  const { fontSize, slideInterval } = settingForm;

  const [activeCarousel, setActiveCarousel] = useState<number>(1);

  return (
    <div className="flex h-screen">
      <div className="w-full md:w-2/3 h-full text-inherit">
        <CustomerHeader />

        <CustomerTemplates />
      </div>
      <div className="hidden md:block md:w-1/3 bg-gray-300 overflow-hidden object-center relative">
        <Carousel
          setActiveCarousel={setActiveCarousel}
          autoPlayTimeout={slideInterval}
          opts={{ loop: true }}
        >
          <CarouselContent>
            {settingForm.slideImages.map((value, idx) => (
              <CarouselItem
                key={`${value}${idx}`}
                className="h-full min-h-screen"
              >
                <img
                  src={value}
                  alt="carousel"
                  className="h-full min-h-screen object-cover"
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>

        <div
          className={cn(
            "bg-[#0000009E] p-2 rounded absolute bottom-4 left-4 text-white",
            `text-${fontSize}`
          )}
        >
          {activeCarousel}/{settingForm?.slideImages?.length}
        </div>
        <button
          onClick={() => {
            updateForm(settingForm);
            setOpen(true);
          }}
          className="bg-[#0000009E] p-2 rounded absolute bottom-4 right-4 text-white"
        >
          <SettingsIcon />
        </button>
      </div>
      <CustomerSettingDialog />
    </div>
  );
};

export default Authn(CustomerDisplay);
