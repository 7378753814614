import { AxiosResponse } from "axios";
import { HTTPClientNonAuth } from "internal/base/http";
import { CustomerSettingFormType, SettingFormType } from "../type";
import { FontSizeEnum } from "../const";

export const FindSetting = (): Promise<AxiosResponse> => {
  return HTTPClientNonAuth().get("/queue_display/");
};

export const UpdateSetting = (
  settingForm: SettingFormType
): Promise<AxiosResponse> => {
  const body = {
    connection: {
      status: settingForm.showQueue,
      ip_address: settingForm.ipAdress,
    },
    display: {
      devide_by_order_type: settingForm.isDivideDisplay,
      queue_number_expired: settingForm.queueExpiry,
    },
    status_label: [
      {
        name: settingForm.pendingLabel,
      },
      {
        name: settingForm.readyLabel,
      },
    ],
    images: settingForm.slideImages,
    updated_at: new Date().toISOString(),
  };

  return HTTPClientNonAuth().post("/queue_display/", body);
};

export const FindCustomerSetting = (): Promise<AxiosResponse> => {
  return HTTPClientNonAuth().get("/customer_display/");
};

export const UpdateCustomerSetting = (
  settingForm: CustomerSettingFormType
): Promise<AxiosResponse> => {
  const appearanceLabel: { [key in FontSizeEnum]: string } = {
    [FontSizeEnum.Small]: "Small",
    [FontSizeEnum.Medium]: "Medium",
    [FontSizeEnum.Large]: "Large",
  };

  const body = {
    connection: {
      status: settingForm?.showCustomerDisplay,
      ip_address: settingForm?.ipAdress,
    },
    appearance: appearanceLabel[settingForm?.fontSize],
    slide_show_setting: {
      scale_to_fit: settingForm?.scaleSlide,
      interval: settingForm?.slideInterval,
    },
    images: settingForm?.slideImages,
    updated_at: new Date().toISOString(),
  };
  return HTTPClientNonAuth().post("/customer_display/", body);
};
