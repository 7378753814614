import { createContext, useContext, useEffect } from "react";
import { GetToken } from "./token";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export type UserInfoType = {
  username: string;
  email: string;
  userId: string;
};

const WhoAmI = async (): Promise<UserInfoType> => {
  return { username: "", email: "", userId: "" };
  // return payload from Cookie or API
};

const AuthContext = createContext<{ userInfo: UserInfoType | undefined }>({
  userInfo: undefined,
});

export const useRegionContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <AuthContext.Provider value={useAuthState()}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthState = () => {
  const { data } = useQuery({ queryKey: ["whoami"], queryFn: WhoAmI });
  const navigate = useNavigate();

  useEffect(() => {
    if (GetToken() === "") navigate("/login");
  }, []);

  return {
    userInfo: data,
  };
};
