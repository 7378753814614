import { Button } from "components/ui/button";
import { Card } from "components/ui/card";
import { Progress } from "components/ui/progress";
import { useInstallationContext } from "internal/installation/state/context";
import { useEffect, useState } from "react";

export const InstallationProgress = () => {
  const { openAbort, branch, company, onOpenAbort, isSuccess, setStep } =
    useInstallationContext();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!!openAbort) return;
    const timer = setTimeout(() => setProgress(progress + 1), 100);
    if (progress === 100) {
      isSuccess ? setStep("success") : setStep("failed");
    }
    return () => clearTimeout(timer);
  }, [progress, openAbort, isSuccess, setStep]);

  return (
    <div className="text-center max-w-[380px] w-full px-6">
      <h2 className="text-2xl font-bold mb-4">Installation in progress</h2>
      <p className="mb-8">please wait, it may take a while...</p>

      <Card className="w-full p-5">
        <p>Installing QDS ({progress}%)</p>
        <Progress value={progress} className="w-full bg-slate-200 h-1.5 my-8" />
        <Button
          variant="link"
          className="block w-full text-danger"
          onClick={() => onOpenAbort("cancel")}
        >
          Cancel
        </Button>
      </Card>
      <p className="mt-6 text-sm">
        Installing on company <strong>{company}</strong> branch{" "}
        <strong>{branch}</strong>
      </p>
    </div>
  );
};
