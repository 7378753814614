import { Separator } from "components/ui/separator";
import { useCustomerSetting } from "hooks/useCustomerSetting";
import { months } from "lib/global";
import { useEffect, useState } from "react";

export const CustomerHeader = () => {
  const { settingForm } = useCustomerSetting();
  const { fontSize } = settingForm;

  const [current, setCurrent] = useState({
    time: "",
    date: "",
  });

  useEffect(() => {
    const intervalID = setInterval(() => {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const formattedTime = `${hours}:${minutes}`;

      const month = months[now.getMonth()];
      const dateNumber = now.getDate();
      const year = now.getFullYear();
      const formattedDate = `${dateNumber} ${month} ${year}`;

      setCurrent({
        time: formattedTime,
        date: formattedDate,
      });
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <div className="h-16 border-b flex items-center justify-between px-6 text-primary">
      <div className="flex gap-3 items-center font-bold text-2xl">
        <h2 className={`text-${fontSize} text-2xl`}>#0015</h2>
        <Separator orientation="vertical" className="h-4" />
        <h1 className={`text-${fontSize}`}>Dine In</h1>
      </div>

      <p className={`text-${fontSize}`}>
        {current.date} {current.time}
      </p>
    </div>
  );
};
