import { cn } from "lib/utils";
import { ReactNode } from "react";

interface Props {
  label?: string;
  children: ReactNode;
  direction?: "row" | "col";
}

export default function SettingItemList(props: Props) {
  const { label, children, direction } = props;

  const directionStyle = `flex-${direction || "row"}`;

  return (
    <div className="w-full flex">
      <div className="w-1/4 font-semibold">{label}</div>
      <div className={cn("w-3/4 flex gap-6", directionStyle)}>{children}</div>
    </div>
  );
}
