import { useSetting } from "hooks";
import { Settings } from "lucide-react";

export const QueueHeader = () => {
  const { setOpen, settingForm, updateForm } = useSetting();
  return (
    <div className="bg-gray-800 p-3 relative max-h-[7vh]">
      {!settingForm.isDivideDisplay ? (
        <h1 className="text-white tracking-[0.5em] font-semibold text-xl text-center">
          QUEUE ORDER
        </h1>
      ) : (
        <div className="flex justify-around">
          <h1 className="text-white tracking-[0.5em] font-semibold text-xl text-center">
            DINE IN
          </h1>
          <h1 className="text-white tracking-[0.5em] font-semibold text-xl text-center">
            TAKE AWAY
          </h1>
          <h1 className="text-white tracking-[0.5em] font-semibold text-xl text-center">
            ONLINE
          </h1>
        </div>
      )}
      <button
        onClick={() => {
          updateForm(settingForm);
          setOpen(true);
        }}
        className="absolute right-4 top-0 bottom-0 m-auto"
      >
        <Settings className="text-white" />
      </button>
    </div>
  );
};
