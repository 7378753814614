import { useMutation } from "@tanstack/react-query";
import { GetInstallment } from "internal/base/auth/installment";
import { GetToken, SetToken } from "internal/base/auth/token";
import ErrorHandler from "internal/base/middleware/error-handler";
import { LoginEmployeeId, LoginEmployeePIN } from "internal/login/http";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useLoginState = () => {
  const [loginType, setLoginType] = useState<"pin" | "employeeId">(
    "employeeId"
  );
  const [codeValue, setCodeValue] = useState<string>("");
  const navigate = useNavigate();

  const employeeIdMutation = useMutation({
    mutationFn: LoginEmployeeId,
    onSuccess: () => setLoginType("pin"),
    onError: (err) => {
      ErrorHandler(err);
      setLoginType("pin");
      setCodeValue("");
    },
  });

  const pinMutation = useMutation({
    mutationFn: LoginEmployeePIN,
    onSuccess: () => {
      SetToken(codeValue);
      navigate("/");
    },
    onError: (err) => {
      ErrorHandler(err);
      setCodeValue("");
      SetToken(codeValue); //to be removed
      navigate("/"); // to be removed
    },
  });

  useEffect(() => {
    if (codeValue.length === 6) {
      loginType === "employeeId"
        ? employeeIdMutation.mutate(codeValue)
        : pinMutation.mutate(codeValue);
    }
  }, [codeValue, loginType]);

  useEffect(() => {
    if (GetInstallment() === "") navigate("/onboarding");
  }, []);

  useEffect(() => {
    if (GetToken() !== "") navigate("/");
  }, []);

  return {
    loginType,
    codeValue,
    setCodeValue,
    isPending: employeeIdMutation.isPending || employeeIdMutation.isPending,
  };
};
