import {
  CustomerSettingFormType,
  CustomerSettingStateType,
  SettingFormType,
  SettingStateType,
} from "../type";

export enum FontSizeEnum {
  Small = "base",
  Medium = "xl",
  Large = "2xl",
}

export const defaultSettingForm: SettingFormType = {
  showQueue: true,
  ipAdress: "",
  isDivideDisplay: false,
  queueExpiry: 6000,
  pendingLabel: "Preparing",
  readyLabel: "Ready",
  slideImages: [
    "https://placehold.co/637x1080",
    "https://placehold.co/637x1080",
    "https://placehold.co/637x1080",
  ],
};

export const defaultCustomerSettingForm: CustomerSettingFormType = {
  showCustomerDisplay: false,
  ipAdress: "",
  fontSize: FontSizeEnum.Medium,
  scaleSlide: false,
  slideInterval: 6000,
  slideImages: [
    "https://placehold.co/637x1080",
    "https://placehold.co/637x1080",
    "https://placehold.co/637x1080",
  ],
};

export const defaultSettingState: SettingStateType = {
  open: false,
  settingForm: defaultSettingForm,
  setOpen: () => undefined,
  setSettingForm: () => undefined,
  handleSave: () => undefined,
  isLoading: false,
  form: defaultSettingForm,
  updateForm: () => undefined,
};

export const defaultCustomerSettingState: CustomerSettingStateType = {
  open: false,
  settingForm: defaultCustomerSettingForm,
  setOpen: () => undefined,
  setSettingForm: () => undefined,
  handleSave: () => undefined,
  isLoading: false,
  form: defaultCustomerSettingForm,
  updateForm: () => undefined,
};
