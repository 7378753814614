import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { CreateInstallation } from "internal/installation/http";
import { useEffect, useState } from "react";
import {
  InstallationForm,
  InstallationProgress,
  InstallationResultFailed,
  InstallationResultSuccess,
} from "components/organisms/installation/";
import { AxiosResponse } from "axios";
import { CreateInstallationFormType } from "internal/installation/type";
import ErrorHandler from "internal/base/middleware/error-handler";
import { GetInstallment, SetInstallment } from "internal/base/auth/installment";
import { useNavigate } from "react-router-dom";

export type InstallationStateType = {
  step: "form" | "loading" | "success" | "failed";
  setStep: (value: "form" | "loading" | "success" | "failed") => void;
  openAbort: "exit" | "cancel" | undefined;
  onCloseAbort: () => void;
  mutate: UseMutateFunction<
    AxiosResponse<any, any>,
    Error,
    CreateInstallationFormType,
    void
  >;
  renderContent: () => JSX.Element | undefined;
  company: string;
  setCompany: (value: string) => void;
  branch: string;
  setBranch: (value: string) => void;
  onOpenAbort: (type: "exit" | "cancel") => void;
  isSuccess: boolean;
};

export const useInstallationState = (): InstallationStateType => {
  const navigate = useNavigate();
  const [step, setStep] = useState<"form" | "loading" | "success" | "failed">(
    "form"
  );
  const [company, setCompany] = useState<string>("");
  const [branch, setBranch] = useState<string>("");
  const [openAbort, setOpenAbort] = useState<"exit" | "cancel">();

  const { mutate, isSuccess } = useMutation({
    mutationFn: CreateInstallation,
    onError: ErrorHandler,
    onMutate: () => setStep("loading"),
    onSettled: () => SetInstallment(`${company}-${branch}`),
  });

  const onCloseAbort = () => setOpenAbort(undefined);
  const onOpenAbort = (type: "exit" | "cancel") => setOpenAbort(type);

  useEffect(() => {
    if (GetInstallment() !== "") navigate("/login");
  }, []);

  const renderContent = () => {
    switch (step) {
      case "form":
        return <InstallationForm />;
      case "loading":
        return <InstallationProgress />;
      case "success":
        return <InstallationResultSuccess />;
      case "failed":
        return <InstallationResultFailed />;
      default:
        return;
    }
  };

  return {
    step,
    setStep,
    openAbort,
    onCloseAbort,
    mutate,
    renderContent,
    company,
    setCompany,
    branch,
    setBranch,
    onOpenAbort,
    isSuccess,
  };
};
