import { cn } from "lib/utils";
import { Check, Clock } from "lucide-react";
import { useMemo } from "react";

type QueueSectionPropsType = {
  type: "success" | "muted";
  title: string;
  divided: boolean;
};

const items = Array.from({ length: 7 }, (_, i) => i + 1);

export const QueueSection = (props: QueueSectionPropsType) => {
  const top = useMemo(() => items.slice(0, 8).slice(0, -4), []);
  const bottom = useMemo(() => items.slice(0, 8).slice(-4), []);

  return (
    <>
      <div
        className={cn(
          "rounded-xl p-2 flex justify-center h-[7vh]",
          props.type === "success" ? "bg-green-300" : "bg-gray-200"
        )}
      >
        <div className="flex gap-2 items-center">
          {props.type === "success" ? (
            <Check className=" w-7 h-7 text-success" />
          ) : (
            <Clock className=" w-7 h-7" />
          )}
          <h1
            className={cn("font-bold uppercase tracking-widest text-3xl", {
              "text-success": props.type === "success",
            })}
          >
            {props.title}
          </h1>
        </div>
      </div>
      <div className="hidden grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 min-h-[16.5vh] min-h-[33vh]" />

      {top.length > 0 && (
        <div className={cn("grid gap-3", `grid-cols-${top.length}`)}>
          {top.map((value) => (
            <div
              key={value}
              className={cn(
                "min-h-[16.5vh] rounded-xl flex justify-center items-center  font-bold text-6xl",
                props.type === "success"
                  ? "bg-green-400 text-white"
                  : "bg-gray-300"
              )}
            >
              {`000${value}`}
            </div>
          ))}
        </div>
      )}
      <div className={cn("grid gap-3", `grid-cols-${bottom.length}`)}>
        {bottom.map((value) => (
          <div
            key={value}
            className={cn(
              "rounded-xl flex justify-center items-center  font-bold text-6xl",
              props.type === "success"
                ? "bg-green-400 text-white"
                : "bg-gray-300",
              `min-h-[${top.length > 0 ? "16.5" : "34"}vh]`
            )}
          >
            {`000${value}`}
          </div>
        ))}
      </div>
    </>
  );
};
