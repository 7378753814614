import { defaultCustomerSettingState } from "internal/setting/const";
import { useCustomerSettingState } from "internal/setting/state/customer";
import { CustomerSettingStateType } from "internal/setting/type";
import { ReactNode, createContext, useContext } from "react";

const CustomerSettingContext = createContext<CustomerSettingStateType>(
  defaultCustomerSettingState
);

export const useCustomerSetting = (): CustomerSettingStateType => {
  const context = useContext(CustomerSettingContext);
  if (!context) throw Error("Error Context");
  return context;
};

export const CustomerSettingProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <CustomerSettingContext.Provider value={useCustomerSettingState()}>
      {children}
    </CustomerSettingContext.Provider>
  );
};
