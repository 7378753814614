import { useEffect, useReducer, useState } from "react";
import { CustomerSettingFormType, CustomerSettingStateType } from "../type";
import { FontSizeEnum, defaultCustomerSettingForm } from "../const";
import { toast } from "sonner";
import { FindCustomerSetting, UpdateCustomerSetting } from "../http";
import ErrorHandler from "internal/base/middleware/error-handler";

export const useCustomerSettingState = (): CustomerSettingStateType => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [settingForm, setSettingForm] = useReducer(
    (prev: CustomerSettingFormType, next: any): CustomerSettingFormType => {
      return { ...prev, ...next };
    },
    defaultCustomerSettingForm
  );

  const [form, updateForm] = useReducer(
    (prev: CustomerSettingFormType, next: Partial<CustomerSettingFormType>) => {
      return { ...prev, ...next };
    },
    settingForm
  );

  useEffect(() => {
    FindCustomerSetting()
      .then((res) => {
        const data = res.data.data?.[0] || {};

        const appearance: { [key: string]: FontSizeEnum } = {
          Small: FontSizeEnum.Small,
          Medium: FontSizeEnum.Medium,
          Large: FontSizeEnum.Large,
        };

        setSettingForm({
          showCustomerDisplay: data?.connection.status,
          ipAdress: data?.connection?.ip_address,
          fontSize: appearance[data?.appearance || "Medium"],
          scaleSlide: data?.slide_show_setting?.scale_to_fit,
          slideInterval: data?.slide_show_setting?.interval,
          // slideImages: data?.images,
        });
      })
      .catch(ErrorHandler);
  }, []);

  const handleSave = (values: CustomerSettingFormType) => {
    setIsLoading(true);

    UpdateCustomerSetting(values)
      .then(() => {
        setSettingForm(values);
        toast.success("Success Save Configuration");
      })
      .catch(ErrorHandler)
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  };

  return {
    open,
    setOpen,
    setSettingForm,
    handleSave,
    settingForm,
    isLoading,
    form,
    updateForm,
  };
};
