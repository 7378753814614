import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { router } from "route";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "components/ui/sonner";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster expand position="top-right" />
    </QueryClientProvider>
  </>
);
